export const AVAILABLE_KPI_PARAMETERS = [
  {
    id: 'articles',
    name: 'articles',
    dataStatus: 'final'
  },
  {
    id: 'photostory',
    name: 'photostory',
    dataStatus: 'final'
  },
  {
    id: 'quiz',
    name: 'quiz',
    dataStatus: 'final'
  },
  {
    id: 'images',
    name: 'images',
    dataStatus: 'final'
  },
  {
    id: 'videos',
    name: 'videos',
    dataStatus: 'final'
  },
  {
    id: 'pageViews',
    name: 'pageViews',
    dataStatus: 'final'
  },
  {
    id: 'videoViews',
    name: 'videoViews',
    dataStatus: 'final'
  },
  {
    id: 'pageViewsNormalized',
    name: 'pageViewsNormalized',
    dataStatus: 'final'
  },
  {
    id: 'avgArticleCompletion',
    name: 'avgArticleCompletion',
    dataStatus: 'final',
    kpiSettings: 'withoutCalculation',
    // dataStatus: 'requiresRecalculation',
    calculationSource: ['articleCompletion', 'articlesTotal']
  },
  {
    id: 'avgDailyUsers',
    name: 'avgDailyUsers',
    dataStatus: 'final',
    kpiSettings: 'withoutCalculation',
    // dataStatus: 'requiresRecalculation',
    calculationSource: ['dailyUsers', 'articlesTotal']
  },
  {
    id: 'avgGalleryCompletion',
    name: 'avgGalleryCompletion',
    dataStatus: 'final',
    kpiSettings: 'withoutCalculation',
    // dataStatus: 'requiresRecalculation',
    calculationSource: ['galleryCompletion', 'articlesTotal']
  },
  {
    id: 'avgTimeSpentPerUserArticle',
    name: 'avgTimeSpentPerUserArticle',
    dataStatus: 'final',
    kpiSettings: 'withoutCalculation',
    // dataStatus: 'requiresRecalculation',
    calculationSource: ['timeSpentPerUserArticle', 'articlesTotal'],
    type: 'time'
  },
  {
    id: 'avgTimeSpentPerUserGallery',
    name: 'avgTimeSpentPerUserGallery',
    dataStatus: 'final',
    kpiSettings: 'withoutCalculation',
    // dataStatus: 'requiresRecalculation',
    calculationSource: ['timeSpentPerUserGallery', 'articlesTotal'],
    type: 'time'
  },
  {
    id: 'avgDailyUsersOrganic',
    name: 'avgDailyUsersOrganic',
    dataStatus: 'final'
  }
]

export function filterOutZeroValueKpiParameters (departmentKpi) {
  if (typeof departmentKpi !== 'object' || departmentKpi === null) {
    return AVAILABLE_KPI_PARAMETERS
  }

  return AVAILABLE_KPI_PARAMETERS.filter(parameter => {
    const value = departmentKpi[parameter.id]
    return value !== undefined && value !== null && value !== 0
  })
}
