<template>
  <div class="card" v-if="isLoaded">
    <div class="card-header">
      <h3>{{ $t('kpi.kpi_fulfilment') }}</h3>
      <label class="btn btn-secondary m-r-5" :class="{ 'active': viewType === 'basic' }">
        <input type="radio" v-model="viewType" value="basic"> {{ $t('kpi.kpi_basic_view') }}
      </label>
      <label class="btn btn-secondary m-r-5" :class="{ 'active': viewType === 'averages' }">
        <input type="radio" v-model="viewType" value="averages"> {{ $t('kpi.kpi_averages_view') }}
      </label>
      <label class="btn btn-secondary m-r-5" :class="{ 'active': viewType === 'users' }">
        <input type="radio" v-model="viewType" value="users"> {{ $t('kpi.kpi_users_view') }}
      </label>
    </div>
    <div class="card-body">
      <app-kpi-basic-view
        v-if="viewType === 'basic' || viewType === 'averages'"
        :view-type="viewType"
      ></app-kpi-basic-view>
      <app-kpi-users-list v-else></app-kpi-users-list>
    </div>
    <div class="info-mark">
      <span v-html="$t('kpi.info_mark')"></span>
    </div>
  </div>
</template>

<script>
import KpiBasicView from './KpiBasicView'
import KpiUsersList from './KpiUsersList'

export default {
  name: 'KpiViews',
  data () {
    return {
      viewType: 'basic'
    }
  },
  computed: {
    isLoaded () {
      return this.$store.getters['editorialPerformance/loadedDepartment']
    }
  },
  components: {
    appKpiBasicView: KpiBasicView,
    appKpiUsersList: KpiUsersList
  }
}
</script>

<style lang="scss" scoped>

.info-mark {
  margin: 0 1rem 1rem 1rem;
}

</style>
