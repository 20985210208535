<template>
  <div class="row">
    <div class="col-lg-2">{{ $t('kpi.kpi_parameters.' + name) }}
      <app-tooltip
        v-if="$t('kpi.kpi_parameters_tooltip.' + name).length"
        :title="$t('kpi.kpi_parameters_tooltip.' + name)"
        customInfoClass="seo"
      ></app-tooltip>
    </div>
    <div class="col-lg-6 mb-1">
      <div class="progress kpiProgress">
        <div
          class="progress-bar text-dark"
          role="progressbar"
          :style="{width: getKpiCompletion + '%', backgroundColor: getBarColor(getKpiCompletion) }"
        >
        <span v-if="valueType === 'time'"> {{ convertSecondsToTime(performance) }} </span>
        <span v-else>{{ performance | formatNumber(2) }}</span>
        </div>
      </div>
    </div>
    <div class="col-lg-2 text-right">
      <span v-if="valueType === 'time'"> {{ convertSecondsToTime(kpi) }} </span>
      <span v-else>{{ kpi | formatNumber(2) }}</span>
    </div>
    <div class="col-lg-2 text-center">{{ getKpiCompletion }}%</div>
  </div>
</template>

<script>
import Tooltip from '../../tooltip/Tooltip'

export default {
  name: 'KpiComparisonProgressRow',
  props: {
    name: {
      type: String,
      required: true
    },
    performance: {
      type: Number,
      default: 0
    },
    kpi: {
      type: Number,
      default: 0
    },
    valueType: {
      type: String,
      default: null,
      required: false
    }
  },
  methods: {
    convertSecondsToTime (value) {
      if (this.valueType === 'time' && typeof value === 'number') {
        const minutes = Math.floor(+value / 60)
        const seconds = Math.floor(+value % 60)
        const formattedMinutes = minutes.toString().padStart(2, '0')
        const formattedSeconds = seconds.toString().padStart(2, '0')
        return `${formattedMinutes}:${formattedSeconds}`
      }
      return value.toString()
    },
    getBarColor (completion) {
      if (completion > 100) {
        completion = 100
      }
      return 'hsl(' + completion + ', 100%, 50%)'
    }
  },
  computed: {
    getKpiCompletion () {
      if (this.kpi > 0) {
        return Math.floor((this.performance / this.kpi) * 100)
      }
      return 0
    }
  },
  components: {
    appTooltip: Tooltip
  }
}
</script>

<style lang="scss" scoped>
  .kpiProgress {
    height: 13px;
    font-size: 11px;
    margin: 5px;
  }
  .kpiProgress:first-of-type {
    margin-bottom: 1px;
  }
  .row:hover {
    background-color: #f7f7f7;
  }
</style>
