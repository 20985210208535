<template>
  <app-module-view>
    <template slot="body">
      <app-preloader-full v-if="callingAPI" :stroke-width="5"></app-preloader-full>
      <app-editorial-performance-filter :filterType="filterType"></app-editorial-performance-filter>
      <app-kpi-views></app-kpi-views>
      <app-kpi-articles-doughnut v-if="isKpiDataActive()" :isLoaded="isLoaded"></app-kpi-articles-doughnut>
      <div class="card" v-else-if="isLoaded">
        <div class="card-body">
          <div class="row font-weight-bold" style="text-align: center">
            <div class="col-lg-12">{{ $t('kpi.user_data_not_found') }}</div>
          </div>
        </div>
      </div>
      <app-users-list :isLoaded="isLoaded" :filterType="filterType"></app-users-list>
      <app-digital-articles-list :isLoaded="isLoaded"></app-digital-articles-list>
      <app-print-articles-list :isLoaded="isLoaded"></app-print-articles-list>
      <app-videos-list :isLoaded="isLoaded"></app-videos-list>
    </template>
  </app-module-view>
</template>

<script>
import ModuleView from '../ModuleView'
import EditorialPerformanceFilter from './EditorialPerformanceFilter'
import DigitalArticlesList from './components/DigitalArticlesList'
import PrintArticlesList from './components/PrintArticlesList'
import VideosList from './components/VideosList'
import UsersList from './components/UsersList'
import KpiArticlesDoughnut from './components/KpiArticlesDoughnut'
import KpiViews from './components/KpiViews'
import PreloaderFull from '../preloader/PreloaderFull'
import { mapState } from 'vuex'

export default {
  name: 'DepartmentPerformance',
  data () {
    return {
      filterType: 'department'
    }
  },
  methods: {
    isKpiDataActive () {
      if (parseInt(this.filter.user) === 0) {
        return true
      }
      return this.usersMetrics.some(obj => {
        return obj.userId === parseInt(this.filter.user)
      })
    }
  },
  created () {
    this.filter.product = ''
    this.$store.commit('editorialPerformance/setLoadedProduct', false)
  },
  computed: {
    ...mapState([
      'callingAPI'
    ]),
    isLoaded () {
      return this.$store.getters['editorialPerformance/loadedDepartment']
    },
    usersMetrics () {
      return this.$store.getters['editorialPerformance/usersMetrics']
    },
    filter () {
      return this.$store.getters['editorialPerformance/filter']
    }
  },
  components: {
    appModuleView: ModuleView,
    appEditorialPerformanceFilter: EditorialPerformanceFilter,
    appDigitalArticlesList: DigitalArticlesList,
    appPrintArticlesList: PrintArticlesList,
    appVideosList: VideosList,
    appUsersList: UsersList,
    appKpiArticlesDoughnut: KpiArticlesDoughnut,
    appKpiViews: KpiViews,
    appPreloaderFull: PreloaderFull
  }
}
</script>
