<template>
  <div>
    <div class="row font-weight-bold">
      <div class="col-lg-2">{{ $t('kpi.kpi_type') }}</div>
      <div class="col-lg-6">{{ $t('kpi.kpi_current') }}</div>

      <div class="col-lg-2 text-right" v-if="viewType === 'basic'">{{ $t('kpi.kpi_target') }}</div>
      <div class="col-lg-2 text-right" v-else>{{ $t('kpi.department_average') }}</div>

      <div class="col-lg-2 text-center" v-if="viewType === 'basic'">{{ $t('kpi.kpi_percentage') }}</div>
      <div class="col-lg-2 text-center" v-else>vs. {{ $t('kpi.average') }}</div>
    </div>
    <kpi-progress-row
      v-for="kpiParameter in filteredKpiParameters" :key="kpiParameter.id"
      :name="kpiParameter.id"
      :performance="getPerformance(kpiParameter.id)"
      :kpi="getTargetValue(kpiParameter.id)"
      :valueType="getType(kpiParameter.id)"
    />
    <kpi-progress-row
      v-if="viewType === 'averages'"
      name="videoArticles"
      :performance="getVideoArticlesPerformance"
      :kpi="getVideoArticlesKpi"
    />
  </div>
</template>

<script>
import KpiProgressRow from './KpiProgressRow'
import PerformanceKpi from '@/model/PerformanceKpi'
import { mapGetters } from 'vuex'
import { AVAILABLE_KPI_PARAMETERS, filterOutZeroValueKpiParameters } from '@/model/ValueObject/KpiParameters'

export default {
  name: 'KpiBasicView',
  props: ['viewType'],
  components: {
    kpiProgressRow: KpiProgressRow
  },
  data () {
    return {
      kpiParameters: AVAILABLE_KPI_PARAMETERS
    }
  },
  computed: {
    ...mapGetters({
      departmentKpi: 'editorialPerformance/kpi',
      performance: 'editorialPerformance/performanceStatistics',
      kpi: 'editorialPerformance/kpi',
      usersKpi: 'editorialPerformance/usersKpi',
      usersMetrics: 'editorialPerformance/usersMetrics',
      isLoaded: 'editorialPerformance/loadedDepartment',
      filter: 'editorialPerformance/filter'
    }),
    usersAverages () {
      const averages = this._.cloneDeep(PerformanceKpi)
      this.kpiParameters.forEach((parameter) => {
        if (parameter.dataStatus !== 'requiresRecalculation') {
          // For user KPIs that do not require recalculation, we assign values directly
          const values = this.usersMetrics.map(userMetric => userMetric[parameter.id])
          averages[parameter.id] = this.calculateAverage(values)
        } else {
          // For user KPIs that require recalculation
          const values = this.usersMetrics.map(userMetric => {
            const numerator = userMetric[parameter.calculationSource[0]]
            const denominator = userMetric[parameter.calculationSource[1]]
            if (denominator === 0) {
              return 0
            }
            return numerator / denominator
          })
          averages[parameter.id] = this.calculateAverage(values)
        }
      })
      return averages
    },
    filteredKpiParameters () {
      return filterOutZeroValueKpiParameters(this.departmentKpi.departmentKpi)
    },
    getVideoArticlesPerformance () {
      if (this.filter.user > 0) {
        const userMetrics = this.usersMetrics.find(obj => {
          return parseInt(obj.userId) === parseInt(this.filter.user)
        })
        if (userMetrics) {
          return userMetrics.articlesWithVideo
        }
        return 0
      }
      return this.performance.articlesWithVideo
    },
    getVideoArticlesKpi () {
      return this.calculateMedian(this.usersMetrics.map(userMetric => userMetric.articlesWithVideo))
    }
  },
  methods: {
    getType (id) {
      const parameter = this.kpiParameters.find(p => p.id === id)
      return parameter && parameter.type ? parameter.type : null
    },
    getTargetValue (parameter) {
      if (this.viewType === 'basic') {
        return this.getKpi(parameter)
      }
      return this.usersAverages[parameter]
    },
    getPerformance (name) {
      if (this.filter.user > 0) {
        const userPerformance = this._.cloneDeep(PerformanceKpi)
        const userMetrics = this.usersMetrics.find(obj => {
          return parseInt(obj.userId) === parseInt(this.filter.user)
        })
        if (userMetrics) {
          this.kpiParameters.forEach(function (parameter) {
            if (parameter.dataStatus !== 'requiresRecalculation') {
              userPerformance[parameter.id] = userMetrics[parameter.id]
            } else {
              if (userMetrics[parameter.calculationSource[1]] === 0) {
                userPerformance[parameter.id] = 0
              }
              userPerformance[parameter.id] = userMetrics[parameter.calculationSource[0]] / userMetrics[parameter.calculationSource[1]]
            }
          })
        }
        return Math.round((userPerformance[name] + Number.EPSILON) * 100) / 100
      }
      return this.performance[name]
    },
    getKpi (name) {
      if (this.filter.user > 0 && this.usersKpi) {
        const user = this.usersKpi.find(obj => {
          return parseInt(obj.userId) === parseInt(this.filter.user)
        })
        if (user) {
          return user[name]
        }
        return this._.cloneDeep(PerformanceKpi)[name]
      }
      return this.kpi.departmentKpi[name]
    },
    calculateAverage (values) {
      let sum = 0
      for (let i = 0; i < values.length; i++) {
        sum += parseInt(values[i], 10)
      }
      return sum / values.length
    },
    calculateMedian (values) {
      if (values.length === 0) {
        return 0
      }
      values.sort(function (a, b) {
        return a - b
      })
      const half = Math.floor(values.length / 2)
      if (values.length % 2) {
        return values[half]
      }
      return (values[half - 1] + values[half]) / 2.0
    }
  }
}
</script>
